import axios from 'axios';
axios.defaults.withXSRFToken = true;
axios.defaults.xsrfHeaderName = "X-CSRF-TOKEN";

export default {
  async updateCode({state, dispatch, commit}, talentCode) {
    try {
      commit('SET_CODE', talentCode);
      commit('SET_LAST_ADD_TALENT_CODE', talentCode);
      commit('SET_PLACEHOLDER', talentCode === 'SNG' ? 'Голос' : 'Инструменты');
      localStorage.setItem('lastAddedTalentCode', talentCode);

      const request = `talantCode=${talentCode}&languageCode=RUS&startRowNum=0&pageSize=1000`;
      await dispatch('getToolList', request);
      await dispatch('getStyleList', request);
      await dispatch('getUserTalant', talentCode);
      await dispatch("getServiceList", talentCode);
    } catch (error) {
      console.error('Error while updating talent code:', error);
    }
  },
  async updateTalant(context, code) {
    try {
      const apiUrl = '/api/user/syncTalant';
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

      const tools = Array.isArray(context.getters.talantTools) && context.getters.talantTools.length > 0
        ? { Tool: context.getters.talantTools.map(obj => ({ Code: obj.Code })) }
        : {};

      const styles = Array.isArray(context.getters.talantStyles) && context.getters.talantStyles.length > 0
        ? { Style: context.getters.talantStyles.map(obj => ({ Code: obj.Code })) }
        : {};

      const talant = context.getters.Talant || {};
      const data = {
        InIO: {
          IntObjectName: "UserTalant",
          ListOfUserTalant: {
            UserTalant: [
              {
                Code: talant.Code,
                CreativeName: talant.CreativeName,
                About: talant.About,
                ListOfTool: tools,
                ListOfStyle: styles,
              },
            ],
          },
        },
      };
      const response = await axios.post(apiUrl, data, { headers });
      const userInfo = response.data;

      if (userInfo.outIO?.ListOfUserInfo?.UserInfo?.length) {
        const userObj = userInfo.outIO.ListOfUserInfo.UserInfo[0];
        context.commit('SET_CURRENT_USER', userObj);
      }

      await context.dispatch('showMessage', {
        type: "success",
        message: "Данные таланта успешно обновлены",
      });
    } catch (error) {
      await context.dispatch('showMessage', {
        type: "error",
        message: "Ошибка обновления таланта пользователя",
      });
      console.error('Ошибка обновления таланта пользователя:', error.message);
    }
  },
  async getTalantList(context) {
    const apiUrl = '/api/glossary/getTalantList';
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    const data = {
      "languageCode": "RUS",
    }
    const response = await axios.post(apiUrl, data, {headers});
    const serviceData = response.data;
    context.commit('SET_TALANT_LIST', serviceData.outIO.ListOfTalantList.Talants);
  },
  async getServiceList(context, payload){
     try {
        const apiUrl = '/api/public/getServiceList';
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
        const data = {
          "languageCode": "RUS",
          "talantCode": payload
        }
        console.log(payload)
        const response = await axios.post(apiUrl, data, {headers});
        const serviceData = response.data;
        console.log(serviceData)
        context.commit('SET_SERVICELIST', serviceData.outIO.ListOfServiceList.Service);

     } catch (error) {
        console.log(error)
       throw error
     }
  },

  async addUserTalantService (context, payload){
     try {
       const apiUrl = '/api/user/addUserTalantService';
       const headers = {
         'Accept': 'application/json',
         'Content-Type': 'application/json'
       }
       const data = {
         "talantCode": payload.talantCode,
         "serviceCode": payload.serviceCode,
         "serviceName": payload.serviceName,
         "cost": payload.costByAgreeFlag ? "" : payload.cost,
         "unit": payload.costByAgreeFlag ? "" : payload.unit,
         "costByAgreeFlag": payload.costByAgreeFlag ? 'Y' : 'N'
       }
       const response = await axios.post(apiUrl, data, {headers});
       const serviceData = response.data;
       await context.dispatch('showMessage', {type: "success", message: "Услуга успешно добавлена"});

     } catch (error) {
       await context.dispatch('showMessage', {type: "error", message: "Ошибка добавления"});
       throw error;
     }
  },
  async updateUserTalantService (context, payload){
    try {
      const apiUrl = '/api/user/updateUserTalantService';
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      const data = {
        "talantCode": payload.talantCode,
        "serviceCode": payload.serviceCode,
        "serviceId": payload.serviceId,
        "serviceName": payload.serviceName,
        "cost": payload.costByAgreeFlag ? "" : payload.cost,
        "unit": payload.costByAgreeFlag ? "" : payload.unit,
        "costByAgreeFlag": payload.costByAgreeFlag ? 'Y' : 'N'
      }
      const response = await axios.post(apiUrl, data, {headers});
      const serviceData = response.data;
      await context.dispatch('showMessage', {type: "success", message: "Услуга успешно изменена"});

    } catch (error) {
      await context.dispatch('showMessage', {type: "error", message: "Ошибка добавления"});
      throw error;
    }
  },
  async deleteUserTalantService (context, payload) {
    try {
      const apiUrl = '/api/user/deleteUserTalantService';
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      const data = {
        "talantCode": payload.talantCode,
        "serviceId": payload.serviceId,
      }
      const response = await axios.post(apiUrl, data, {headers});
      const serviceData = response.data;
      await context.dispatch('showMessage', {type: "success", message: "Услуга успешно удалена"});

    } catch (error) {
      await context.dispatch('showMessage', {type: "error", message: "Ошибка добавления"});
      throw error;
    }
  }



}
