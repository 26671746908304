import axios from "axios";

import {syncFileVideo} from "@/api/fn";
import {parseDurationToSeconds, extractYouTubeVideoId, extractVideoIds, formatDataMaster} from "@/utilities/media"

export default {
  updateAudioDataField({commit}, payload) {
    commit("SET_AUDIO_DATA_FIELD", payload);
  },
  updateLinkDataField({commit}, payload) {
    commit("SET_LINK_DATA_FIELD", payload);
  },
  updateVideoData({commit}, payload) {
    commit('SET_VIDEO_DATA_FIELD', payload);
  },

  // processLink({ commit }) {
  //   commit("PROCESS_LINK_DATA_FIELD");
  // },
  previewCoverVideo({commit, state, dispatch, getters}, event) {
    const input = event.target;

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          if (width && height) {
            commit('SET_LINK_DATA_FIELD', {field: 'width', value: width});
            commit('SET_LINK_DATA_FIELD', {field: 'height', value: height});
            // this.dataLink.thumbnail = e.target.result;

          } else {
            console.error("Ошибка получения размеров изображения.");
          }
        };

        img.onerror = () => {
          console.error("Ошибка загрузки изображения.");
        };

        // Устанавливаем источник изображения для отображения
        img.src = e.target.result;


        // Устанавливаем превью как результат чтения файла
        // this.dataLink.thumbnail = e.target.result;

        // Отображаем превью через метод getPreviewImage()

      };
      commit('SET_COVER_TO_UPLOAD_VIDEO', input.files[0]);
      reader.readAsDataURL(input.files[0]);
    }
  },

  async HANDLE_VK_LINK({commit, dispatch, getters}, link) {
    const ids = await extractVideoIds(link);
    if (ids) {
      commit('SET_LINK_DATA_FIELD', {field: 'ownerId', value: ids.ownerId});
      commit('SET_LINK_DATA_FIELD', {field: 'videoId', value: ids.videoId});
      dispatch('fetchVideoDetailsJSONP')
    } else {
      commit('SET_ERROR_MESSAGE', 'Неверный формат ссылки на VK видео');
    }
  },
  async processLink({getters, commit, dispatch}) {
    const link = getters.videoLink.trim();
    if (link.startsWith('https://vk.com/')) {
      commit('SET_VIDEO_DATA_FIELD', {field: 'hostingService', value: 'VK'});
      const ids = await extractVideoIds(link);
      if (link.includes('video')) {
        commit('SET_VIDEO_DATA_FIELD', {field: 'subType', value: 'VKVideo'});
        commit('SET_VIDEO_DATA_FIELD', {field: 'orientation', value: 'Horizontal'});
      } else if (link.includes('clip')) {
        commit('SET_VIDEO_DATA_FIELD', {field: 'subType', value: 'VKClip'});
        commit('SET_VIDEO_DATA_FIELD', {field: 'orientation', value: 'Vertical'});
      }

      if (ids) {
        commit('SET_LINK_DATA_FIELD', {field: 'ownerId', value: ids.ownerId});
        commit('SET_LINK_DATA_FIELD', {field: 'videoId', value: ids.videoId});
        dispatch('HANDLE_VK_LINK', link);
      } else {
        commit('SET_ERROR_MESSAGE', 'Неверный формат ссылки на VK видео');
      }
    } else if (
      link.startsWith('https://www.youtube.com/') ||
      link.startsWith('https://youtu.be/') ||
      link.includes('/shorts/')
    ) {
      await dispatch('handleYouTubeLink', link);
    } else {
      commit('SET_ERROR_MESSAGE', 'Данная видео платформа не поддерживается');
    }
  },
  async fetchVideoDetailsJSONP({commit, state}) {
    const {ownerId, videoId} = state.dataLink;
    if (!ownerId || !videoId) {
      commit("SET_ERROR_MESSAGE", "Неверные идентификаторы видео");
      return;
    }

    const videosParam = `${ownerId}_${videoId}`;
    const accessToken = process.env.VUE_APP_VK_VIDEO_KEY;
    const callbackName = `jsonp_callback_${Date.now()}_${Math.random().toString(36).substr(2, 6)}`;
    const url = `https://api.vk.com/method/video.get?videos=${videosParam}&access_token=${accessToken}&v=5.131&callback=${callbackName}`;

    window[callbackName] = (data) => {
      delete window[callbackName];
      document.body.removeChild(script);

      if (data.response?.items?.length) {
        const video = data.response.items[0];
        commit("SET_VIDEO_DATA_FIELD", {field: "duration", value: video.duration});
        commit("SET_VIDEO_DATA_FIELD", {
          field: "fileName",
          value: `${video.title}_${Date.now()}_${Math.random().toString(36).substr(2, 6)}`,
        });
        commit("SET_VIDEO_DATA_FIELD", {field: "originName", value: video.title});
        commit("SET_VIDEO_DATA_FIELD", {field: "fileURL", value: `${video.player}&hd=2`});
        commit("SET_VIDEO_DATA_FIELD", {field: "description", value: video.description});

        commit("SET_LINK_DATA_FIELD", {field: "width", value: video.width});
        commit("SET_LINK_DATA_FIELD", {field: "height", value: video.height});
        commit("SET_LINK_DATA_FIELD", {
          field: "thumbnail",
          value: video.image?.length ? video.image.sort((a, b) => b.width - a.width)[0].url : "",
        });
        commit("SET_VIDEO_LOAD", true)
      } else {
        commit("SET_ERROR_MESSAGE", `Ошибка VK API: ${data.error?.error_msg || "Неизвестная ошибка"}`);
      }
    };

    const script = document.createElement("script");
    script.src = url;
    script.onerror = () => {
      delete window[callbackName];
      document.body.removeChild(script);
      commit("SET_ERROR_MESSAGE", "Не удалось загрузить информацию о видео");
    };

    document.body.appendChild(script);
  },
  async handleYouTubeLink({commit, state, dispatch, getters}, link) {
    commit('SET_VIDEO_DATA_FIELD', {field: 'hostingService', value: 'YouTube'});
    const videoId = extractYouTubeVideoId(link);
    if (videoId) {
      commit('SET_LINK_DATA_FIELD', {field: 'videoId', value: videoId});
      commit('SET_ADDED_LINK', true);

      const iFrameLink = `https://www.youtube.com/embed/${videoId}`;
      commit('SET_VIDEO_DATA_FIELD', {field: 'fileURL', value: iFrameLink});
      const isShorts = link.includes('/shorts/');
      commit('SET_VIDEO_DATA_FIELD', isShorts ? {field: 'subType', value: 'YouTubeShort'} : {
        field: 'subType',
        value: 'YouTubeVideo'
      });
      commit('SET_VIDEO_DATA_FIELD', isShorts ? {field: 'orientation', value: 'Vertical'} : {
        field: 'orientation',
        value: 'Horizontal'
      });
      await dispatch('fetchYouTubeVideoDetailsJSONP', videoId);
    } else {
      commit('SET_ERROR_MESSAGE', 'Неверный формат ссылки на YouTube видео');
    }
  },
  async fetchYouTubeVideoDetailsJSONP({ commit, dispatch }, videoId) {
    const apiKey = process.env.VUE_APP_YOUTUBE_KEY;
    const callbackName = `jsonp_callback_${Date.now()}_${Math.random().toString(36).substr(2, 6)}`;
    const url = `https://youtube.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${videoId}&key=${apiKey}&callback=${callbackName}`;

    window[callbackName] = async (data) => {
      try {
        delete window[callbackName];
        document.body.removeChild(script);

        if (data?.items?.length) {
          const video = data.items[0];
          const duration = video.contentDetails.duration;
          const parsedDuration = await parseDurationToSeconds(duration);

          await dispatch("updateVideoData", { field: "duration", value: parsedDuration });
          await dispatch("updateVideoData", {
            field: "fileName",
            value: `${video.snippet.title}_${Date.now()}_${Math.random().toString(36).substring(2, 8)}`,
          });
          await dispatch("updateVideoData", { field: "originName", value: video.snippet.title });
          await dispatch("updateVideoData", { field: "description", value: video.snippet.description });
          await dispatch("updateLinkDataField", { field: "thumbnail", value: video.snippet.thumbnails?.standard?.url || "" });

        } else {
          commit("SET_ERROR_MESSAGE", "Не удалось получить данные о видео");
        }
      } catch (error) {
        console.error("Ошибка при обработке данных:", error);
        commit("SET_ERROR_MESSAGE", "Произошла ошибка при обработке данных о видео");
      }
    };
    // !!!ВРЕМЕННОЕ ОПОВЕЩЕНИЕ!!!
    await dispatch("showMessage", {
      type: "success",
      message: "Возможна медленная скорость загрузки видео с Youtube",
    });

    const script = document.createElement("script");
    script.src = url;
    script.onerror = () => {
      delete window[callbackName];
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
      commit("SET_ERROR_MESSAGE", "Не удалось загрузить информацию о видео");
    };

    document.body.appendChild(script);
  },
  async previewCoverAudio({state, dispatch}, event) {
    const input = event.target;

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.onload = async () => {

          const width = img.width;
          const height = img.height;

          if (width && height) {
            await dispatch("updateAudioDataField", {field: "coverWidth", value: width});
            await dispatch("updateAudioDataField", {field: "coverHeight", value: height});
            await dispatch("updateAudioDataField", {field: "logo", value: e.target.result});

          } else {
            console.error("Ошибка получения размеров изображения.");
          }
        };

        img.onerror = () => {
          console.error("Ошибка загрузки изображения.");
        };

        img.src = e.target.result;
      };
      await dispatch("updateAudioDataField", {field: "coverToUpload", value: input.files[0]});
      reader.readAsDataURL(input.files[0]);
    }
  },

  
  async saveAudio({state, getters, commit, dispatch}) {
    const talants = formatDataMaster('Talants', getters.selectTalants);
    const tools = formatDataMaster('Tools', getters.selectTools);
    const styles = formatDataMaster('Styles', getters.selectStyles);
    await dispatch("updateAudioDataField", {field: "talants", value: talants});
    await dispatch("updateAudioDataField", {field: "tools", value: tools});
    await dispatch("updateAudioDataField", {field: "styles", value: styles});
    const responseSyncFile = await dispatch("syncFileAudio", state.audioData);
    if (responseSyncFile.status === 200) {
      await dispatch('getFilesUser', 'AUDIO')
      commit("DELETE_AUDIO_DATA");
      await dispatch("showMessage", {
        type: "success",
        message: "Успешно сохранено",
      });

    } else {
      await dispatch("showMessage", {
        type: "error",
        message: "Ошибка сохранения",
      });
    }
  },
  async saveVideo({commit, state, dispatch, getters}) {
    const talants = formatDataMaster('Talants', getters.selectTalants);
    const tools = formatDataMaster('Tools', getters.selectTools);
    const styles = formatDataMaster('Styles', getters.selectStyles);

    await dispatch("updateLinkDataField", {field: "talants", value: talants});
    await dispatch("updateLinkDataField", {field: "tools", value: tools});
    await dispatch("updateLinkDataField", {field: "styles", value: styles});
    try {
      const dataFile = {
        fileName: state.videoLink,
        fileExt: '',
        name: state.videoData.originName,
        description: state.videoData.description,
        comment: '',
        fileType: 'Video',
        fileSize: '',
        fileFullURL: state.videoData.fileURL,
        logoId: state.dataLink.logoId,
        AvailableFlag: 'Y',
        ownerFlag: state.videoData.ownerFlag ? 'Y' : 'N',
        publicFlag: state.videoData.publicFlag ? 'Y' : 'N',
        talants: state.dataLink.talants,
        tools: state.dataLink.tools,
        styles: state.dataLink.styles,
        duration: state.videoData.duration,
        width: state.dataLink.width,
        height: state.dataLink.height,
        orientation: state.videoData.orientation,
        subType: state.videoData.subType
      };
      await dispatch("syncFileVideo", dataFile);
      commit('SET_LOADING', false);
      commit('SET_VIDEO_LINK', '');
      commit("CLEAR_VIDEO_DATA");
      await dispatch('getFilesUser', 'VIDEO');
      await dispatch("showMessage", {
        type: "success",
        message: "Успешно сохранено",
      });
    } catch (error) {
      console.error(error);
      await dispatch("showMessage", {
        type: "error",
        message: "Ошибка сохранения",
      });
    }
  },
  async saveMasterVideo({getters, dispatch}) {
    if (getters.ifThubnailString) {
      return await dispatch('updateFormImageVideo', getters.dataLink.thumbnail);
    }
    return await dispatch("saveVideo");
  },
  async updateFormImageAudio({commit, state, dispatch}, dataImg) {
    const newFile = {
      file: dataImg,
      type: 'IMAGE',
      subType: 'Cover',
      externalFlag: 'N',
      hostingService: '',
      originName: state.audioData.coverToUpload.name,
      setAsAvatar: false
    }

    const responseLoadImage = await dispatch("uploadImage", newFile);

    if (responseLoadImage.status === 200) {

      const {fileName, url, ext, size} = responseLoadImage.data;
      const {externalFlag, hostingService, originName, type} = newFile;
      const sendNewFile = {
        duration: state.audioDuration,
        fileName,
        fileURL: url,
        fileExt: ext,
        fileSize: size,
        fileType: type,
        externalFlag,
        hostingService,
        originName,
      }
      await dispatch("updateAudioDataField", {field: "logo", value: url});
      const responceGetInfoFile = await dispatch("getInfoFile", sendNewFile);

      if (responceGetInfoFile.status === 200) {
        await dispatch("updateAudioDataField", {field: "logoId", value: responceGetInfoFile.data.fileId});
      } else {
        await dispatch("showMessage", {
          type: "error",
          message: "Ошибка получения идентификатора изображения",
        });

        await dispatch("updateAudioDataField", {field: "logoId", value: ""});
      }

    } else {
      await dispatch("showMessage", {
        type: "error",
        message: "Ошибка загрузки изображения",
      });
    }
    await dispatch("updateAudioDataField", {field: "coverToUpload", value: null});
  },
  async updateFormImageVideo({commit, state, getters, dispatch}, imageFile) {
    commit('SET_LOADING', true)

    if (typeof imageFile === 'string') {
      // Если imageFile - это ссылка
      commit('SET_LINK_DATA_FIELD', {field: 'logo', value: imageFile});

      // Формируем объект данных для отправки на сервер
      const linkData = {
        fileName: imageFile,
        fileURL: imageFile,
        fileExt: '',
        fileSize: '',
        fileType: 'IMAGE',
        subType: 'Cover',
        externalFlag: 'Y',
        originName: state.videoData.originName
      };
      const responseGetInfoFile = await dispatch("getInfoFile", linkData);
      if (responseGetInfoFile.status === 200) {
        commit('SET_LINK_DATA_FIELD', {field: 'logoId', value: responseGetInfoFile.data.fileId});
        await dispatch('saveVideo');
      } else {
        await dispatch("showMessage", {
          type: "error",
          message: "Ошибка получения идентификатора изображения",
        });
      }
    } else if (imageFile instanceof File) {
      commit('SET_THUBNAIL_STRING', false)
      commit('SET_LOADING', true)

      try {
        const reader = new FileReader();
        // this.noReadyPhotoToSubmit = true;????

        reader.onload = async (e) => {

          const newFileData = {
            file: imageFile,
            type: 'PHOTO',
            externalFlag: 'N',
            hostingService: '',
            originName: imageFile.name,
            setAsAvatar: false,
          };

          const responseLoadImage = await dispatch("uploadImage", newFileData);

          if (responseLoadImage.status === 200) {
            const {fileName, url, ext, size} = responseLoadImage.data;
            const sendNewFile = {
              duration: state.videoData?.duration || '',
              fileName: fileName,
              fileURL: url,
              fileExt: ext,
              fileSize: size,
              fileType: newFileData.type,
              externalFlag: newFileData.externalFlag,
              hostingService: newFileData.hostingService,
              originName: newFileData.originName
            };
            commit('SET_LINK_DATA_FIELD', {field: 'thumbnail', value: url});
            commit('SET_LINK_DATA_FIELD', {field: 'logo', value: url});

            await dispatch("showMessage", {
              type: "success",
              message: "Изображение загружено на сервер",
            });

            const responseGetInfoFile = await dispatch("getInfoFile", sendNewFile);

            if (responseGetInfoFile.status === 200) {
              // this.noReadyPhotoToSubmit = false; ?????
              commit('SET_LINK_DATA_FIELD', {field: 'logoId', value: responseGetInfoFile.data.fileId});
            } else {
              await dispatch("showMessage", {
                type: "error",
                message: "Ошибка получения идентификатора изображения",
              });
            }
          } else {
            await dispatch("showMessage", {
              type: "error",
              message: "Ошибка загрузки изображения",
            });
          }
        };

        reader.readAsDataURL(imageFile);
        commit('SET_LOADING', false)
      } catch (error) {
        console.error("Error uploading image:", error);
        await dispatch("showMessage", {
          type: "error",
          message: "Ошибка загрузки изображения",
        });
      }
    } else {
      console.error("updateFormImage expects a File object or a URL string.");
    }
  },

  async getInfoFile({commit,}, fileData) {
    const apiUrl = "/api/user/addUserFile";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(apiUrl, fileData, {headers});
      commit("SET_FILE_INFO", response.data);

      return {status: response.status, data: response.data};
    } catch (error) {
      console.error("Ошибка при получении информации о файле:", error);
      return {status: error.response?.status || 500};
    }
  },
  async uploadImage({commit}, data) {
    const formData = new FormData();
    const apiUrl = "/api/storage";
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    formData.append("file", data.file);

    try {
      const response = await axios({
        method: "post",
        headers: headers,
        url: apiUrl,
        data: formData,
      });

      if (response.status === 200) {
        commit("SET_FILE_INFO", response.data);
      }

      return {status: response.status, data: response.data};
    } catch (error) {
      return {status: error.response?.status || 500};
    }
  },
  async previewAudioMaster({commit, state, getters, dispatch}, event) {
    commit('SET_LOAD_FILE_AUDIO', true);
    const input = event.target;
    const file = event.target.files[0];

    // Проверка на допустимый тип файла
    if (!getters.allowedAudioTypes.includes(file.type)) {
      await dispatch("showMessage", {
        type: "error",
        message: "Неподдерживаемый формат файла! Загрузите аудиофайл.",
      });
      commit('SET_LOAD_FILE_AUDIO', false);
      return;
    }

    // Проверка на допустимое расширение файла
    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (!getters.allowedExtensions.includes(fileExtension)) {
      await dispatch("showMessage", {
        type: "error",
        message: "Недопустимое расширение файла! Поддерживаемые расширения: mp3, wav, ogg, aac.",
      });
      commit('SET_LOAD_FILE_AUDIO', false);
      return;
    }

    // this.fileName = file.name;
    await dispatch("updateAudioDataField", {field: "audioName", value: file.name});

    const dataFile = await dispatch("uploadAudioFile", file);
    if (dataFile.status === 200) {
      await dispatch("updateAudioDataField", {field: "origFileName", value: file.name});
      await dispatch("updateAudioDataField", {field: "fileFormat", value: file.type});
      let objectUrl = URL.createObjectURL(file);
      commit('SET_AUDIO_PREVIEW', objectUrl)
      await dispatch("updateAudioDataField", {field: "file", value: input.files[0]});
      await dispatch("updateAudioDataField", {field: "fileName", value: dataFile.data.fileName});
      await dispatch("updateAudioDataField", {field: "fileURL", value: dataFile.data.url});
      await dispatch("updateAudioDataField", {field: "fileExt", value: dataFile.data.ext});
      await dispatch("updateAudioDataField", {field: "fileSize", value: dataFile.data.size});


      const audio = new Audio(objectUrl);
      audio.onloadedmetadata = async () => {
        await dispatch("updateAudioDataField", {field: "audioDuration", value: audio.duration});
        commit('SET_AUDIO_DURATION', audio.duration)

        if (!state.audioData.audioDuration) {
          await dispatch("showMessage", {
            type: "error",
            message: "Ошибка загрузки продолжительности файла",
          });
        }
        commit('SET_LOAD_FILE_AUDIO', false);
      };
    } else {
      await dispatch("showMessage", {
        type: "error",
        message: "Ошибка загрузки файла",
      });
      commit('SET_LOAD_FILE_AUDIO', false);
    }
  },
  async uploadAudioFile({commit}, file) {
    let formData = new FormData();
    const apiUrl = "/api/storage";
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    formData.append("file", file);

    try {
      const response = await axios({
        method: "post",
        headers: headers,
        url: apiUrl,
        data: formData,
      });

      if (response.status === 200) {
        commit("SET_AUDIO_FILE_INFO", response.data);
      }

      return {status: response.status, data: response.data};
    } catch (error) {
      return {status: error.response?.status || 500};
    }
  },
  async syncFileAudio({commit, getters}, dataFile) {
    if (!dataFile || typeof dataFile !== "object") {
      return {status: 400, data: "Некорректные данные файла"};
    }

    const apiUrl = "/api/user/syncFile";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const createFileName = (file) => {
      const fileName = file.fileName
      try {
        if (!fileName) {
          let name = file.audioName?.trim().replace(/\s+/g, "_") || "default";
          return `${name}-${Date.now()}`;
        }
        return fileName;
      } catch (err) {
        return `default-${Date.now()}`;
      }
    }
    const data = {
      InIO: {
        IntObjectName: "UserFile",
        ListOfUserFile: {
          File: [
            {
              FileId: dataFile.FileId,
              FileName: createFileName(dataFile),
              FileExt: dataFile.fileExt || "",
              Name: dataFile.audioName,
              Description: dataFile.audioDescription,
              Comment: dataFile.comment,
              FileType: dataFile.fileType,
              FileSize: dataFile.fileSize,
              FileFullURL: dataFile.fileURL ?? dataFile.externalURL,
              LogoId: dataFile.logoId,
              AvailableFlag: dataFile.AvailableFlag,
              DeletedFlag: dataFile.DeletedFlag,
              Status: dataFile.Status,
              OwnerFlag: dataFile.ownerFlag ? "Y" : "N",
              PublicFlag: dataFile.publicFlag ? "Y" : "N",
              ListOfTalants: dataFile.talants,
              ListOfTools: dataFile.tools,
              ListOfStyles: dataFile.styles,
              Duration: dataFile.audioDuration,
              Width: dataFile.coverWidth,
              Height: dataFile.coverHeight,
            },
          ],
        },
      },
    };

    try {
      const response = await axios.post(apiUrl, data, {headers});

      if (response.status === 200) {
        commit("UPDATE_SYNCED_FILE", response.data);
      }

      return {status: response.status, data: response.data};
    } catch (error) {
      return {
        status: error.response?.status || 500,
        data: error.response?.data || error.message || "Ошибка сети",
      };
    }
  },
  async syncFileVideo({commit}, dataFile) {
    const apiUrl = "/api/user/syncFile";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const data = {
      InIO: {
        IntObjectName: "UserFile",
        ListOfUserFile: {
          File: [
            {
              FileId: dataFile.FileId,
              FileName: dataFile.fileName,
              FileExt: "",
              Name: dataFile.name,
              Description: dataFile.description,
              Comment: dataFile.comment,
              FileType: dataFile.fileType,
              FileSubType: dataFile.subType,
              FileSize: dataFile.fileSize,
              FileFullURL: dataFile.fileFullURL,
              LogoId: dataFile.logoId,
              AvailableFlag: dataFile.AvailableFlag,
              DeletedFlag: dataFile.DeletedFlag,
              Status: dataFile.Status,
              OwnerFlag: dataFile.ownerFlag ? "Y" : "N",
              PublicFlag: dataFile.publicFlag ? "Y" : "N",
              ListOfTalants: dataFile.talants,
              ListOfTools: dataFile.tools,
              ListOfStyles: dataFile.styles,
              Duration: dataFile.duration,
              Width: dataFile.width,
              Height: dataFile.height,
              Orientation: dataFile.orientation,
              SubType: dataFile.subType,
            },
          ],
        },
      },
    };

    try {
      const response = await axios.post(apiUrl, data, {headers});

      if (response.status === 200) {
        commit("UPDATE_SYNCED_VIDEO_FILE", response.data);
      }

      return {status: response.status, data: response.data};
    } catch (error) {
      console.error("Ошибка при синхронизации видеофайла:", error);
      return {status: error.response?.status || 500, data: error};
    }
  },
}
