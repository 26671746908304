import actions from "./actions";

const state = {
  allowedAudioTypes: ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/aac', 'audio/vnd.dlna.adts'],
  allowedExtensions: ['mp3', 'wav', 'ogg', 'aac'],
  previewNoImage: require('@/assets/images/no_image.png'),
  errorMessage: "",
  videoLink: "",
  isAddedLink: false,
  isEditeAudio: false,
  videoIsLoad: false,
  isLoading: false,
  loadFileAudio: false,
  audioToPreview: null,
  coverToUploadVideo: "",
  audioDuration: "",
  ifThubnailString: true,
  selectTalants: [],
  selectTools: [],
  selectStyles: [],
  fileInfo: {},
  audioData: {
    mode: 'new',
    comment: "",
    coverWidth: "",
    coverHeight: "",
    externalURL: "",
    logo: "",
    logoId: "",
    audioDuration: "",
    audioName: "",
    origFileName: "",
    fileFormat: "",
    file: null,
    coverToUpload: null,
    fileName: "",
    fileURL: "",
    fileType: "Audio",
    fileExt: "",
    fileSize: 0,
    externalFlag: false,
    originalFileName: "",
    ownerFlag: true,
    publicFlag: true,
    audioDescription: "",
    talants: [],
    tools: [],
    styles: []
  },
  dataLink: {
    talants: [],
    tools: [],
    styles: [],
    orientation: "",
    videoId: "",
    ownerId: "",
    description: "",
    width: "",
    height: "",
    thumbnail: "",
    logo: "",
    logoId: ""
  },
  videoData: {
    logo: '',
    duration: '',
    externalFlag: 'Y',
    fileName: '',
    fileExt: '',
    description: '',
    fileType: 'Video',
    fileURL: '',
    hostingService: '',
    originName: '',
    orientation: '',
    subType: '',
    ownerFlag: true,
    publicFlag: true,
  }
}

const getters = {
  editAudioUpload: (state) => state.audioData,
  coverToUploadVideo: (state) => state.coverToUploadVideo,
  videoData: (state) => state.videoData,
  allowedAudioTypes: (state) => state.allowedAudioTypes,
  allowedExtensions: (state) => state.allowedExtensions,
  previewNoImage: (state) => state.previewNoImage,
  dataLink: (state) => state.dataLink,
  isAddedLink: (state) => state.isAddedLink,
  videoLink: (state) => state.videoLink,
  fileInfo: (state) => state.fileInfo,
  videoIsLoad: (state) => state.videoIsLoad,
  selectTalants: (state) => state.selectTalants,
  selectTools: (state) => state.selectTools,
  selectStyles: (state) => state.selectStyles,
  isLoading: (state) => state.isLoading,
  ifThubnailString: (state) => state.ifThubnailString,
  loadFileAudio: (state) => state.loadFileAudio,
  audioToPreview: (state) => state.audioToPreview,
  audioDuration: (state) => state.audioDuration,
  errorMessage: (state) => state.errorMessage,
  isEditeAudio: (state) => state.isEditeAudio,
  getPreviewImage: (state) => (url) => {
    return url === "" ? state.previewNoImage : url;
  },
}

const mutations = {
  DELETE_AUDIO_DATA(state) {
    state.isEditeAudio = false;
    state.audioData = {
      mode: 'new',
      comment: "",
      coverWidth: "",
      coverHeight: "",
      externalURL: "",
      logo: "",
      logoId: "",
      audioDuration: "",
      audioName: "",
      origFileName: "",
      fileFormat: "",
      file: null,
      coverToUpload: null,
      fileName: "",
      fileURL: "",
      fileType: "Audio",
      fileExt: "",
      fileSize: 0,
      externalFlag: false,
      originalFileName: "",
      ownerFlag: true,
      publicFlag: true,
      audioDescription: "",
      talants: [],
      tools: [],
      styles: []
    }
    state.selectTalants = [];
    state.selectTools = [];
    state.selectStyles = [];
  },
  CLEAR_VIDEO_DATA(state) {
    state.errorMessage = '';
    state.videoLink = '';
    state.videoData = {
      logo: "",
      duration: "",
      externalFlag: 'Y',
      fileName: "",
      fileExt: "",
      description: "",
      fileType: 'Video',
      fileURL: "",
      hostingService: "",
      originName: "",
      orientation: "",
      subType: "",
      ownerFlag: true,
      publicFlag: true,
    }
    state.dataLink = {
      talants: [],
      tools: [],
      styles: [],
      orientation: '',
      videoId: '',
      ownerId: '',
      description: '',
      width: '',
      height: '',
      thumbnail: state.previewNoImage,
      logo: '',
      logoId: ''
    }
    state.selectTalants = [];
    state.selectTools = [];
    state.selectStyles = [];
    state.videoIsLoad = false;
  },
  SET_LOAD_FILE_AUDIO(state, file) {
    state.loadFileAudio = file
  },
  SET_VIDEO_LOAD(state, value) {
    state.videoIsLoad = value
  },
  SET_ADDED_LINK(state, value) {
    state.isAddedLink = value
  },
  SET_AUDIO_DATA_FIELD(state, { field, value }) {
    if (Object.prototype.hasOwnProperty.call(state.audioData, field)) {
      state.audioData[field] = value;
    } else {
      console.warn(`Поле "${field}" не найдено в audioData`);
    }
  },
  SET_LINK_DATA_FIELD(state, { field, value }) {
    if (Object.prototype.hasOwnProperty.call(state.dataLink, field)) {
      state.dataLink[field] = value;
    } else {
      console.warn(`Поле "${field}" не найдено в dataLink`);
    }
  },
  SET_VIDEO_DATA_FIELD(state, { field, value }) {
    state.videoData = { ...state.videoData, [field]: value };
  },
  SET_THUBNAIL_STRING(state, value) {
    state.ifThubnailString = value;
  },
  SET_VIDEO_LINK(state, link) {
    state.videoLink = link;
  },
  SET_AUDIO_PREVIEW(state, audio) {
    state.audioToPreview = audio;
  },
  SET_AUDIO_DURATION(state, duration) {
    state.audioDuration = duration;
  },
  SET_COVER_TO_UPLOAD_VIDEO(state, files) {
    state.coverToUploadVideo = files;
  },
  SET_LOADING(state, value) {
    state.isLoading = value
  },
  SET_SELECT_TALANTS(state, talants) {
    state.selectTalants = talants;
  },
  SET_SELECT_TOOLS(state, tools) {
    state.selectTools = tools;
  },
  SET_SELECT_STYLES(state, style) {
    state.selectStyles = style;
  },
  SET_FILE_INFO(state, fileNewInfo) {
    state.fileInfo = fileNewInfo;
  },
  UPDATE_SYNCED_FILE(state, syncedData) {
    state.fileInfo = syncedData;
  },
  UPDATE_SYNCED_VIDEO_FILE(state, syncedData) {
    state.fileInfo = syncedData;
  },
  SET_ERROR_MESSAGE(state, message) {
    state.errorMessage = message;
  },
  SET_VIDEO_DATA(state, video) {
    state.isAddedLink = true;
    state.videoLink = video.FileName;
    state.videoData.originName = video.Name;
    state.videoData.description = video.Description;
    state.dataLink.thumbnail = video.LogoURL;
    state.dataLink.logoId = video.LogoId;
    state.videoData.duration = video.Duration;
    state.dataLink.width = video.Width;
    state.dataLink.height = video.Height;
    state.videoData.orientation = video.Orientation;
    state.videoData.subType = video.SubType;
    state.videoData.fileURL = video.FileFullURL;
    state.videoData.fileType = 'Video';

    state.dataLink.styles = video?.ListOfStyles?.Styles || [];
    state.dataLink.tools = video?.ListOfTools?.Tools || [];
    state.dataLink.talants = video?.ListOfTalants?.Talants || [];
  },
  SET_AUDIO_DATA(state, audio) {
    state.isEditeAudio = true
    state.audioData.origFileName = audio.OriginName;
    state.audioData.audioDuration = audio.Duration;
    state.audioData.audioName = audio.Name;
    state.audioData.audioDescription = audio.Description;
    state.audioData.logo = audio.LogoURL;
    state.audioData.logoId = audio.LogoId;
    state.audioData.fileURL = audio.FileFullURL;
    state.audioData.fileType = 'Audio';
    state.audioData.fileName = audio.FileName;
    state.audioData.fileExt = audio.FileExt;
    state.audioData.fileSize = audio.FileSize;
    state.audioData.comment = audio.Comment;
    state.audioData.ownerFlag = audio.OwnerFlag === 'Y';
    state.audioData.publicFlag = audio.PublicFlag === 'Y';
    state.audioData.externalFlag = false;
    state.selectTalants = audio?.ListOfTalants?.Talants || [];
    state.selectTools = audio?.ListOfTools?.Tools || [];
    state.selectStyles = audio?.ListOfStyles?.Styles || [];
  }

}

export default {
  state,
  getters,
  actions,
  mutations,
};

