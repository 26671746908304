import axios from 'axios';
import router from '@/router';

const currentVersion = process.env.VUE_APP_VERSION;

axios.interceptors.response.use(
  (response) => {
    const storedVersion = localStorage.getItem('currentVersion');

    if (currentVersion && currentVersion !== storedVersion && response.config.method === 'get') {
      localStorage.setItem('currentVersion', currentVersion);
      window.location.reload();
    }

    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      const errorMessage = error.response.data?.message || '';
      
      // Проверяем, что это именно истекшая сессия
      if (errorMessage.includes('Session expired') || errorMessage.includes('Token expired')) {
        router.push('/');
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
