export const formatDataMaster = (fieldName, array) => {
  return array.length > 0 ? { [fieldName]: array.map(obj => ({ Code: obj.Code })) } : {};
}
export const parseDurationToSeconds = (duration) => {

  const timeRegex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
  const matches = duration.match(timeRegex);

  const hours = parseInt(matches[1] || '0', 10);
  const minutes = parseInt(matches[2] || '0', 10);
  const seconds = parseInt(matches[3] || '0', 10);

  return hours * 3600 + minutes * 60 + seconds;
}
export const extractYouTubeVideoId = (link) => {
  const regex = /(?:youtube\.com\/(?:watch\?v=|shorts\/|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = link.match(regex);
  return match ? match[1] : null;
}
export const extractVideoIds = (link) => {
  const videoRegex = /video(-?\d+)_(\d+)/;
  const match = link.match(videoRegex);
  if (match) return { ownerId: match[1], videoId: match[2] };

  const clipRegex = /clip(-?\d+)_(\d+)/;
  const clipMatch = link.match(clipRegex);
  if (clipMatch) return { ownerId: clipMatch[1], videoId: clipMatch[2] };

  return null;
}
export const mediaSearchType = (photo, video, audio) => {
  const photoValue = photo ? 2 : 0;
  const audioValue = audio ? 4 : 0;
  const videoValue = video ? 8 : 0;

  return photoValue + videoValue + audioValue;
}
