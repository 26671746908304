//import getters from './getters'
import actions from "./actions";
//import mutations from './mutations'

const state = {
  isRegConfirm: false,
  neonFlag: false,
  regData: null,
  isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
  currentUser: {},
  availableTalants: {},
  userTalants: [],
  userTools: [],
  userStyles: [],
  showInputResetCode: false,
  audioFiles: [],
  photoFiles:[],
  currentAudioId: null, // ID текущего проигрываемого файла
  audioStatus: 'stopped', // Статус проигрывания ('playing', 'paused', 'stopped')
  audioElement: null, // Элемент аудио
};


const getters = {
  neonFlag: (state) => state.neonFlag,
  isRegConfirm: (state) => state.isRegConfirm,
  regData: (state) => state.regData,
  isLoggedIn: (state) => state.isLoggedIn,
  currentUser: (state) => state.currentUser,
  availableTalants: (state) => state.availableTalants,
  userTalants: (state) => state.userTalants,
  userTools: (state) => state.userTools,
  userStyles: (state) => state.userStyles,
  userStatus: (state) => state.currentUser.Status,
  showInputResetCode: (state) => state.showInputResetCode,
  startTimer: (state) => state.startTimer,
  audioFiles:(state)=> state.audioFiles,
  videoFiles: (state) => state.videoFiles,
  photoFiles:(state)=> state.photoFiles,
};

const mutations = {
  DELETE_USER_TALANT(state, talantCode) {
    state.userTalants = state.userTalants.filter(talant => talant.Code !== talantCode);
    state.availableTalants.Talants = state.availableTalants.Talants.filter(talent => talent.Code !== talantCode);
  },
  SET_SHOW_INPUT_RESET_CODE(state, value) {
    state.showInputResetCode = value;
  },

  SET_REGDATA(state, data) {
    state.regData = data;
  },
  SET_REGCONFIRM(state, status) {
    state.isRegConfirm = status;
  },
  SET_LOGIN(state, status) {
    state.isLoggedIn = status;
    localStorage.setItem('isLoggedIn', status ? 'true' : 'false');
  },
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
  },
  SET_CURRENT_USER_AVAILABLE_TALANTS(state, talants) {
    state.availableTalants = talants;
  },
  SET_CURRENT_USER_TALANTS(state, talants) {
    state.userTalants = talants;
  },
  SET_CURRENT_USER_TOOLS(state, tools) {
    state.userTools = tools;
  },
  SET_CURRENT_USER_STYLES(state, styles) {
    state.userStyles = styles;
  },
  UpdateFirstName(state, value) {
    state.currentUser.FirstName = value;
  },
  UpdateLastName(state, value) {
    state.currentUser.LastName = value;
  },
  UpdateBirthDate(state, value) {
    state.currentUser.BirthDate = value;
  },
  UpdateAbout(state, value) {
    state.currentUser.AboutMe = value;
  },
  UpdateNickName(state, value) {
    state.currentUser.NickName = value;
  },
  UpdateAvatar(state, value) {
    state.currentUser.Avatar = value;
  },
  UpdateSex(state, value) {
    state.currentUser.Sex = value;
  },
  SET_USER_VIDEO(state, videoFiles) {
    state.videoFiles = videoFiles;
  },
  SET_USER_PHOTO(state, photoFiles) {
    state.photoFiles = Array.isArray(photoFiles)
      ? photoFiles.sort((a, b) => {
          let dateA = new Date(a.FileDate);
          let dateB = new Date(b.FileDate);
          return dateB - dateA;
        })
      : [];

  },

  SET_USER_AUDIO(state, audioFiles) {
    // Проверяем, что audioFiles является массивом, иначе присваиваем пустой массив
    state.audioFiles = Array.isArray(audioFiles)
      ? audioFiles.sort((a, b) => {
          let dateA = new Date(a.AddedDT);
          let dateB = new Date(b.AddedDT);
          return dateB - dateA; // От нового к старому
        })
      : [];
  },
  SET_NEON_FLAG(state, value) {
    state.neonFlag = value === "Y";
  },

  SET_AUDIOSTATUS(state, { fileId, status, time }) {
    if (state.audioFiles) {
      state.audioFiles.forEach((item) => {
        if (item.FileId === fileId) {
          item.audioStatus = status;
          if (time !== undefined) {
            item.currentTime = time;
          }
        } else {
          item.audioStatus = 'stopped';
        }
      });
    } else {
      console.warn("Audio files not found.");
    }
  },


  UpdateTripFlag(state, value) {
    state.currentUser.TripFlag = value;
  },
  UpdateRemoteFlag(state, value) {
    state.currentUser.RemoteFlag = value;
  },
  UpdateAvatarId(state, value) {
    state.currentUser.AvatarId = value;
  },
}
export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
