import axios from "axios";
axios.defaults.withXSRFToken = true;
axios.defaults.xsrfHeaderName = "X-CSRF-TOKEN";

const state = {
  allPosts: [],
  visiblePosts: [],
  scheduledPosts:[],
  deletedPosts: [],
  visibleDeletedPosts:[],
  isLoaderPosts: false,
  isFormOpen: false,

};
const getters = {
  allPosts: state=>state.allPosts,
  visiblePosts: state => state.visiblePosts,
  visibleDeletedPosts: state => state.visibleDeletedPosts,
  deletedPosts: state => state.deletedPosts,
  isLoaderPosts: state => state.isLoaderPosts,
  isFormOpen: state => state.isFormOpen,
  scheduledPosts:state=>state.scheduledPosts
};
const mutations = {
  SET_LIKES(state, like) {
    const post = state.visiblePosts.find((item) => item.PostId === like.postId);
      if (post) {
        post.Likes = like.likesNum;
        post.LikeFlag = like.likeFlag;
      }
    },
  SET_0PEN_FORM(state, value) {
    state.isFormOpen = value
  },
  SET_USER_POSTS(state, value) {
    state.allPosts = (value.outIO?.ListOfUserPostList?.UserPost || [])
    .sort((a, b) => {
      const dateA = new Date(a.DisplayDT);
      const dateB = new Date(b.DisplayDT);
      return dateB - dateA;
    });
  },
  SET_SCHEDULED_POSTS(state, value) {
    state.scheduledPosts = (state.allPosts.filter(post=>post.Status==="Sheduled") || [] )
    .sort((a, b) => {
      const dateA = new Date(a.DisplayDT);
      const dateB = new Date(b.DisplayDT);
      return dateA - dateB;
    });
  },
  SET_LOADER_POSTS(state, value) {
    state.isLoaderPosts = value
  },
  SET_DELETED_POSTS(state, value) {
    state.deletedPosts = (value.outIO?.ListOfUserPostList?.UserPost || [])
      .sort((a, b) => {
        const dateA = new Date(a.DisplayDT);
        const dateB = new Date(b.DisplayDT);
        return dateB - dateA; // Сортировка по убыванию даты (самые новые посты первыми)
      });

  },

  SET_VISIBLE_POSTS(state) {
    const startIndex = state.visiblePosts.length;
    const endIndex = startIndex + 10;

    const newPosts = state.allPosts.slice(startIndex, endIndex);

      if (newPosts.length > 0) {
        state.visiblePosts.push(...newPosts);
      } else {
        console.log("No more posts to load");
      }
  },
  SET_VISIBLE_DELETED_POSTS(state) {
    const startIndex = state.visibleDeletedPosts.length;
    const endIndex = startIndex + 10;


    const newPosts = state.deletedPosts.slice(startIndex, endIndex);

      if (newPosts.length > 0) {
        state.visibleDeletedPosts.push(...newPosts);
      } else {
        console.log("No more posts to load");
      }
  },
  CLEAN_POST(state) {
    state.visiblePosts=[]
  },

  REMOVE_POSTS(state, postId) {

    const index = state.visiblePosts.findIndex(post => post.PostId === postId);
    console.log('index',index)
    if (index !== -1) {
      state.visiblePosts.splice(index, 1);

    }
  },



};
const actions = {

  async getUserPostList({ commit }, payload) {
    if (payload.deletedFlag) {
      commit('SET_LOADER_POSTS', true);
     }
    try {
      const response = await fetch('/api/user/post/getUserPostList', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      console.log('data getUserPostList', data)
      if (payload.deletedFlag) {
        commit('SET_DELETED_POSTS', data)
        commit('SET_VISIBLE_DELETED_POSTS')
      } else {
        commit('SET_USER_POSTS', data);
        commit('SET_VISIBLE_POSTS');
        commit('SET_SCHEDULED_POSTS')
      }

      return data;
    } catch (error) {
      console.error('Ошибка в getUserPostList:', error);
      throw error;
    } finally {
      commit('SET_LOADER_POSTS', false);
    }
  },
  async getUserPost({ commit }, payload) {
    try {
      const response = await fetch('/api/user/post/getUserPost', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });


      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Ошибка в getUserPost:', error);
      throw error;
    }
  },
  async createUserPost({ commit }, payload) {
    try {
      const response = await fetch('/api/user/post/createUserPost', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      // console.log('data',data)
      return data;
    } catch (error) {
      console.error('Ошибка в createUserPost:', error);
      throw error;
    }
  },
  async setPostLike({ commit }, payload) {
     console.log('payload ',payload)

    try {
      const response = await fetch('/api/user/post/setUserPostLike', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      return data;

    }catch (error) {
      console.error('Ошибка при проставлении реакции:', error);
      throw error;
    }
   },
  async updateUserPost({ commit }, { postData }) {
    commit('SET_LOADER_POSTS', true);
    commit('SET_0PEN_FORM',false)

    try {
      const updatedPost = {
        InIO: {
          IntObjectFormat: "Siebel Hierarchical",
          IntObjectName: "UserPost",
          MessageType: 'Integration Object',
          ListOfUserPost: {
            UserPost: [
              {
                Body: postData.body || '',
                Created: postData.created || "",
                PostId: postData.postId || "",
                Published: postData.published || "",
                Sheduled: postData.sheduled || "",
                Status: postData.status,
                LastUpdated:postData.lastUpdated || "",
                Title: postData.title || "",
                Type: postData.type || "Public",
                UserId: postData.userId || "",
                ListOfUserPost_UserFile: postData.selectedFiles.length > 0
                  ? {
                      UserPost_UserFile: postData.selectedFiles.map(file => ({
                        FileId: file.FileId || '',
                      })),
                    }
                  : {},


              },
            ],
          },
        },
      };


      await axios.post('/api/user/post/updateUserPost', updatedPost);
      commit('CLEAN_POST')
      setTimeout(() => {
        commit('SET_LOADER_POSTS', false);
      },2000)
      // commit('SET_LOADER_POSTS', false);



      // console.log('updatedPost.InIO.ListOfUserPost.UserPost[0].PostId)', updatedPost.InIO.ListOfUserPost.UserPost[0].PostId)
      // commit('ADD_VISIBLE_POSTS', updatedPost.InIO.ListOfUserPost.UserPost[0].PostId);


    } catch (error) {
      console.error('Ошибка при обновлении поста:', error);
      throw error;
    }
  }

};





export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,

};
