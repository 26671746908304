import axios from "axios";
axios.defaults.withXSRFToken = true;
axios.defaults.xsrfHeaderName = "X-CSRF-TOKEN";


export async function getFilesUser(type) {
  try {
    const apiUrl = "/api/user/getUserFileList"; // Замените на ваш URL API
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const data = "includeDeleted=false&onlyDeleted=false&type=" + type;

    const response = await axios.get(apiUrl + "?" + data, { headers });

    const fileList = response.data;



    let filelistObj = [];

    if (fileList.outIO.ListOfUserFileList !== undefined) filelistObj = fileList.outIO.ListOfUserFileList.Files;
    console.log('getFilesUser', filelistObj)

    return filelistObj;
  }
  catch (error) {
    console.error(error);
    return [];
  }
}

export async function deleteUserFile(data) {
  const apiUrl = "/api/user/deleteUserFile";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let dataFile = {
    rowId: data.rowId
  }

  try {
    const response = await axios({
      method: 'post',
      headers: headers,
      url: apiUrl,
      data: dataFile
    });

    return { status: response.status, data: response.data  };
  }
  catch (error) {
    return { status: error.response.status };
  }
}

export async function updateUserContactInfo(data) {
  const apiUrl = "/api/user/updateUserContactInfo";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  try {
    const response = await axios({
      method: 'post',
      headers: headers,
      url: apiUrl,
      data: data
    });

    return { status: response.status, data: response.data };

  } catch ( error ) {
    return { status: error.response.status }
  }
}

export async function updateUserPartnerCode(partnerCode) {
  const apiUrl = "/api/user/updateUserPartnerCode";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  try {
    const response = await axios({
      method: 'post',
      headers: headers,
      url: apiUrl,
      data: {
        "partnerCode": partnerCode
      }
    });

    return { status: response.status, data: response.data };

  } catch ( error ) {
    return { status: error.response.status }
  }
}

// Отрисовка волнового эквалайзера
export async function drawEqualizer (audioId, canvasId, objectUrl) {
  const audio = document.getElementById(audioId);
  this.audioUpload.audioDuration = audio.duration;
  const canvas = document.getElementById(canvasId);
  const canvasCtx = canvas.getContext('2d');

  // Загрузка аудиофайла с помощью fetch
  const response = await fetch(objectUrl);
  const audioData = await response.arrayBuffer();

  // Создание аудиоконтекста
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  const audioBuffer = await audioCtx.decodeAudioData(audioData);

  // Определение параметров волнового эквалайзера
  const bufferLength = audioBuffer.length;
  const dataArray = audioBuffer.getChannelData(0);

  // Отрисовка волнового эквалайзера
  const draw = () => {
    canvasCtx.fillStyle = 'white';
    canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

    canvasCtx.lineWidth = 1;
    canvasCtx.strokeStyle = '#44abc4';
    canvasCtx.beginPath();

    const sliceWidth = canvas.width * 1.0 / bufferLength;
    let x = 0;

    for(let i = 0; i < bufferLength; i++) {
      const y = (dataArray[i] + 1) * canvas.height / 2;

      if(i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();
  };

  // Отображение текущей позиции воспроизведения
  const drawProgress = (currentTime) => {
    const progressX = canvas.width * (currentTime / audioBuffer.duration);
    canvasCtx.globalAlpha = 0.5
    canvasCtx.fillStyle = 'red';
    canvasCtx.fillRect(0, 0, progressX, canvas.height);
  };

  // Обработчик события timeupdate аудио
  audio.addEventListener('timeupdate', () => {
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
    draw();
    drawProgress(audio.currentTime);
  });

  canvas.addEventListener('click', (event) => {
    const x = event.pageX;
    const rect = canvas.getBoundingClientRect();
    const absoluteX = x - rect.left;
    const progress = absoluteX / rect.width;

    // Устанавливаем текущее время аудиоплеера
    audio.currentTime = progress * audioBuffer.duration;

    // Перезапуск воспроизведения, если аудио было на паузе
    if (audio.paused) {
      audio.play();
    }
  });

  draw();
}

export async function loadFileAudio(file) {
  let formData = new FormData();
  const apiUrl = "/api/storage";
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  formData.append("file", file);

  try {

    const response = await axios({
      method: 'post',
      headers: headers,
      url: apiUrl,
      data: formData
    });

    return { status: response.status, data: response.data };

  } catch ( error ) {
    return { status: error.response.status }
  }
}

export async function setUserHeader(fileId,color='') {

  const apiUrl = "/api/user/setUserHeader"; // Добавлен начальный "/"
  const headers = {
    'Accept': 'application/json',   // Заголовок 'Accept' с правильным синтаксисом
    'Content-Type': 'application/json' // Отсутствовала запятая
  };

  const data={
    color: color,
    imgId: fileId
  }


  try {

    const response = await axios({
      method: 'post',
      headers: headers,
      url: apiUrl,
      data,
      withCredentials: true
    });

    return { status: response.status, data: response.data };

  } catch ( error ) {
    return { status: error.response.status }
  }
}

export async function syncFilePhoto(dataFile) {
  const apiUrl = "/api/user/syncFile";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };


  
  const data = {
    InIO: {
      IntObjectName: "UserFile",
      ListOfUserFile: {
        File: [
          {
            FileId: dataFile.fileId,
            // FileName: createFileName(dataFile.fileName),
            FileName: dataFile.fileName,
            FileExt: dataFile.fileExt ?? '',
            Name: dataFile.name,
            Description: dataFile.description,
            Comment: dataFile.comment,
            FileType: dataFile.fileType,
            FileSize: dataFile.fileSize ?? '',
            FileFullURL: dataFile.fileFullURL ,
            LogoId: dataFile.logoId,
            AvailableFlag: dataFile.AvailableFlag,
            DeletedFlag: dataFile.DeletedFlag ?? 'N',
            // Status: dataFile.Status ?? '',
            OwnerFlag: dataFile.ownerFlag === 'Y' ? "Y" : "N",
            PublicFlag: dataFile.publicFlag === 'Y' ? "Y" : "N",
            ListOfTalants: dataFile.talants,
            ListOfTools: dataFile.tools,
            ListOfStyles: dataFile.styles,
            Duration: dataFile.duration ?? '',
            Width: dataFile.width ?? '',
            Height: dataFile.height ?? '',
            Orientation: dataFile.orientation ?? '',
            FileSubType: dataFile.subType ?? '',
          },
        ],
      },
    },
  };

  console.log("data", data);

  try {
    const response = await axios({
      method: 'post',
      headers: headers,
      url: apiUrl,
      data: data,
    });

    return { status: response.status, data: response.data };
  } catch (error) {
    return { status: error.response?.status ?? 500, data: error };
  }
}


export async function syncFile(dataFile) {
  const apiUrl = "/api/user/syncFile";
  // const username = "Master"; // Замените на ваше имя пользователя
  // const password = "ujybim"; // Замените на ваш пароль

  // const auth = "Basic " + btoa(username + ":" + password);
  const headers = {
    // Authorization: auth,
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  let createFileName = (fileName) => {
    if ( !fileName ) {
      let encoder = new TextEncoder();
      let name = dataFile.audioName.length > 0 ? dataFile.audioName : 0;
      let uint8Name = encoder.encode(name);
      let d = Date.now();
      return `${uint8Name.join("").substring(1, dataFile.audioName.length)}-${d}`;
    } else {
      return fileName;
    }
  }

  const data = {
    InIO: {
      IntObjectName: "UserFile",
      ListOfUserFile: {
        File: [
          {
            FileId: dataFile.FileId,
            // FileName: createFileName(dataFile.fileName),
            FileName: dataFile.fileName,
            FileExt: typeof dataFile.fileExt !== 'undefined' ? dataFile.fileExt : '',
            Name: dataFile.audioName,
            Description: dataFile.audioDescription,
            Comment: dataFile.comment,
            FileType: dataFile.fileType,
            FileSize: dataFile.fileSize,
            FileFullURL: dataFile.fileURL ?? dataFile.externalURL,
            LogoId: dataFile.logoId,
            AvailableFlag: dataFile.AvailableFlag,
            DeletedFlag: dataFile.DeletedFlag,
            Status: dataFile.Status,
            OwnerFlag: dataFile.ownerFlag === true ? "Y" : "N",
            PublicFlag: dataFile.publicFlag === true ? "Y" : "N",
            ListOfTalants: dataFile.talants,
            ListOfTools: dataFile.tools,
            ListOfStyles: dataFile.styles,
            Duration: dataFile.audioDuration,
            Width: dataFile.coverWidth,
            Height: dataFile.coverHeight
          },
        ],
      },
    },
  };

  console.log("data", data);

  try {

    const response = await axios({
      method: 'post',
      headers: headers,
      url: apiUrl,
      data: data
    });

    return { status: response.status, data: response.data };

  } catch ( error ) {
    return { status: error.response.status, data: error }
  }
}
export async function syncFileVideo(dataFile) {

  const apiUrl = "/api/user/syncFile";

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const data = {
    InIO: {
      IntObjectName: "UserFile",
      ListOfUserFile: {
        File: [
          {
            FileId: dataFile.FileId ,
            FileName: dataFile.fileName,
            FileExt:  '',
            Name: dataFile.name,
            Description: dataFile.description,
            Comment: dataFile.comment,
            FileType: dataFile.fileType,
            FileSubType: dataFile.subType,
            FileSize: dataFile.fileSize,
            FileFullURL: dataFile.fileFullURL,
            LogoId: dataFile.logoId,
            AvailableFlag: dataFile.AvailableFlag,
            DeletedFlag: dataFile.DeletedFlag,
            Status: dataFile.Status,
            OwnerFlag: dataFile.ownerFlag === true ? "Y" : "N",
            PublicFlag: dataFile.publicFlag === true ? "Y" : "N",
            ListOfTalants: dataFile.talants,
            ListOfTools: dataFile.tools,
            ListOfStyles: dataFile.styles,
            Duration: dataFile.duration,
            Width: dataFile.width,
            Height: dataFile.height,
            Orientation: dataFile.orientation,
            SubType: dataFile.subType,
          },
        ],
      },
    },
  };

  console.log("data fn.js", data);

  try {

    const response = await axios({
      method: 'post',
      headers: headers,
      url: apiUrl,
      data: data
    });

    return { status: response.status, data: response.data };

  } catch ( error ) {
    return { status: error.response.status, data: error }
  }
}

export async function loadImage(data) {
  let formData = new FormData();
  const apiUrl = "/api/storage";
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  formData.append("file", data.file);

  try {

    const response = await axios({
      method: 'post',
      headers: headers,
      url: apiUrl,
      data: formData
    });

    return { status: response.status, data: response.data };

  } catch ( error ) {
    return { status: error.response.status }
  }
}

export async function getInfoFile(data) {
  const apiUrl = "/api/user/addUserFile";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  try {

    const response = await axios({
      method: 'post',
      headers: headers,
      url: apiUrl,
      data: data
    });

    return { status: response.status, data: response.data };

  } catch ( error ) {
    return { status: error.response.status }
  }
}

export function sortStringinObject(data, field, direction) {
  let mapped = data.map(function (el, i) {
    return { index: i, value: el[field].toLowerCase() };
  });

  const toUp = () => {
    mapped.sort(function (a, b) {
      if (a.value > b.value) {
        return 1;
      }
      if (a.value < b.value) {
        return -1;
      }
      return 0;
    });
  }

  const toDown = () => {
    mapped.sort(function (a, b) {
      if (a.value < b.value) {
        return 1;
      }
      if (a.value > b.value) {
        return -1;
      }
      return 0;
    });
  }

  switch (direction) {
    case 'up': toUp();
      break;
    case 'down': toDown();
      break;
  }

  let result = mapped.map((el) => {
    return data[el.index];
  });

  return result;
}

export function sortNumberinObject(data, field, direction) {
  let mapped = data.map(function (el, i) {
    return { index: i, value: el[field] };
  });

  const toUp = () => {
    mapped.sort(function (a, b) {
      return a.value - b.value;
    });
  }

  const toDown = () => {
    mapped.sort(function (a, b) {
      return b.value - a.value;
    });
  }

  switch (direction) {
    case 'up': toUp();
      break;
    case 'down': toDown();
      break;
  }

  let result = mapped.map((el) => {
    return data[el.index];
  });

  return result;
}

export async function checkNickName(data) {
  const apiUrl = "/api/user/checkNickName";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  try {
    const response = await axios({
      method: 'post',
      headers: headers,
      url: apiUrl,
      data: data
    });

    return { status: response.status, data: response.data };
  } catch ( error) {
    return { status: error.response.status }
  }
}
