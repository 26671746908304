import { createApp } from "vue";
import Vuex from "vuex";
import axios from "axios";
import setting from "./setting/index";
import user from "./user/index";
import talant from "./talant/index";
import search from "./search/index";
import posts from "./posts/index";
import media from "./media/index";
import {filterTools} from "@/utilities/talant";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

createApp(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    setting,
    user,
    talant,
    search,
    posts,
    media
  },
  state: {
    filterType: "",
    filterTypeTxt: "",
    filterStyleTxt: "",
    filterPartnerTxt: "",
    toolTypes: [],
    // Возможное будущее решение при расширение логики тулзов
    talentTools: {
      cmp: [],
      sng: [],
      swr: [],
      dnc: [],
      dj: [],
      mus: [],
      mmk: [],
      mst: [],
      pro: [],
      snm: [],
    },
    tools: [],
    sngTools: [],
    dncTools: [],
    styles: [],
    partners: [],
    attrSources: [],
    isLoader: false,
    hint: 'У вас не добавлено ни одного таланта. При поиске ваш профиль не будет отображаться. Добавьте свой талант и вас смогут найти',
    showHint: true,
    loaderState: true
  },
  mutations: {
    SET_LOADER_STATE(state, payload) {
      state.loaderState = payload;
    },
    SET_SHOW_HINT(state, value) {
      state.showHint = value;
    },
    SET_LOADER(state, status) {
      state.isLoader = status;
    },
    SET_TYPETXTFILTER(state, filter) {
      state.filterTypeTxt = filter;
    },
    SET_STYLETXTFILTER(state, filter) {
      state.filterStyleTxt = filter;
    },
    SET_TYPEFILTER(state, filter) {
      state.filterType = filter;
    },
    SET_TOOLTYPES(state, types) {
      state.toolTypes = types;
    },
    SET_TOOLS(state, tools) {
      state.tools = tools;
    },
    SET_TALENT_TOOLS(state, payload) {
      const tools = payload.tools;
      const code = payload.code.toLowerCase();
      state.talentTools[code] = tools
    },
    SET_SNG_TOOLS(state, tools) {
      state.sngTools = tools;
    },
    SET_DANCE_TOOLS(state, tools) {
      state.dncTools = tools;
    },
    SET_STYLES(state, styles) {
      state.styles = styles;
    },
    SET_PARTNERS(state, partners) {
      state.partners = partners;
    },
    SET_ATTRACTION_SRC(state, attrSources) {
      state.attrSources = attrSources;
    },
  },
  getters: {
    isLoader: (state) => state.isLoader,
    filterType: (state) => state.filterType,
    filterTypeTxt: (state) => state.filterTypeTxt,
    filterStyleTxt: (state) => state.filterStyleTxt,
    filterPartnerTxt: (state) => state.filterPartnerTxt,
    attrSources: (state) => state.attrSources,
    toolTypes: (state) => state.toolTypes,
    // Получение тулзов с применением фильтра
    tools: (state) => filterTools(state.tools, state.filterType, state.filterTypeTxt),
    sngTools: (state) => filterTools(state.sngTools, state.filterType, state.filterTypeTxt),
    dncTools: (state) => filterTools(state.dncTools, state.filterType, state.filterTypeTxt),
    styles: (state) => {
      if (state.filterStyleTxt !== "")
        return state.styles
          .filter((item) =>
            item.Name.toLowerCase().includes(
              state.filterStyleTxt.toLowerCase(),
            ),
          )
          .sort((a, b) => a.Name.localeCompare(b.Name));
      else return state.styles;
    },
    partners: (state) => {
      if (state.filterPartnerTxt !== "")
        return state.partners
          .filter((item) =>
            item.Name.toLowerCase().includes(
              state.filterPartnerTxt.toLowerCase(),
            ),
          )
          .sort((a, b) => a.Name.localeCompare(b.Name));
      else return state.partners;
    },
  },
  actions: {
    // Сохраняет список тулзов определенного таланта
    setTools(context, {tools, talentCode}) {

      if (talentCode === "SNG") {
        console.log(talentCode)
        context.commit("SET_SNG_TOOLS", tools);
      } if (talentCode === "DNC") {
        context.commit("SET_DANCE_TOOLS", tools);
      }else if(talentCode !== "SNG" && talentCode !== "DNC") {
        console.log(talentCode)
        context.commit("SET_TOOLS", tools);
      }
    },
    showMessage(context, { type, message }) {
      switch (type) {
        case "success":
          toast.success(message, { autoClose: 3000 });
          break;
        case "error":
          toast.error(message, { autoClose: 3000 });
          break;
      }
    },
    async getToolList(context, data) {
      try {
        const apiUrl = "/api/glossary/getToolList";
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const params = new URLSearchParams(data).toString();
        const response = await axios.get(`${apiUrl}?${params}`, { headers });

        const toolsData = response.data;
        const tools = toolsData?.outIO?.ListOfToolList?.Tools || [];
        const useUrlParamc = new URLSearchParams(data);

        const talentCode = useUrlParamc.get('talantCode');
        if (tools.length > 0) {
          await context.dispatch("setTools", { talentCode, tools });
          const uniqueSet = new Set();
          const uniqueArray = tools.reduce((acc, item) => {
            const key = `${item.Type}-${item.TypeTranslate}`;
            if (!uniqueSet.has(key)) {
              uniqueSet.add(key);
              acc.push({
                Type: item.Type,
                Translate: item.TypeTranslate,
              });
            }
            return acc;
          }, []);

          context.commit("SET_TOOLTYPES", uniqueArray);
        }
      } catch (error) {
        console.error("Ошибка получения инструментов:", error);
      }
    },
    async getStyleList(context, data) {
      try {
        const apiUrl = "/api/glossary/getStyleList"; // Замените на ваш URL API
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const queryString = new URLSearchParams(data).toString();
        const response = await axios.get(`${apiUrl}?${queryString}`, { headers });
        const Data = response.data;
        const styles = Data?.outIO?.ListOfStyleList?.Styles || [];
        if (styles.length > 0) {
          console.log('styles',styles)
          context.commit("SET_STYLES", styles);
        } else {
          console.warn("Styles list is empty");
          context.commit("SET_STYLES", []);
        }
      } catch (error) {
        console.error("Ошибка получения стилей:", error.message);
        context.commit("SET_STYLES", []);
      }
    },
    async getSchoolList(context) {
      try {
        const apiUrl = "/api/glossary/getSchoolList"; // Замените на ваш URL API
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const response = await axios.post(apiUrl, {"languageCode": "RUS" }, { headers });
        const Data = response.data;

        const schools = Data?.outIO?.ListOfStyleList?.School || [];
        if (schools.length > 0) {
          context.commit("SET_PARTNERS", schools);
        } else {
          console.warn("Styles list is empty");
          context.commit("SET_PARTNERS", []);
        }
      } catch (error) {
        console.error("Ошибка получения школ:", error.message);
      }
    },
    async getAttractionSourceList(context) {
      try {
        const apiUrl = "/api/glossary/getAttractionSourceList"; // Замените на ваш URL API
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const response = await axios.post(apiUrl, {"languageCode": "RUS" }, { headers });
        const Data = response.data;
        let attrSource = [];
        attrSource = Data.outIO.ListOfAttractionSourceList.Source;
        if (attrSource !== undefined) {
          context.commit("SET_ATTRACTION_SRC", attrSource);
        }
      } catch (error) {
        console.error("Ошибка получения данных:", error.message);
      }
    },
    async getSessionInfo(context) {
      await context.dispatch('getUserStatistic');
      if (this.getters.isLoggedIn) {
        await context.dispatch('getUserInfo');
      }
  /*    const apiUrl = "/security-session/user"; // Замените на ваш URL API
      await axios
        .get(apiUrl)
        .then((response) => {
          context.commit("SET_LOGIN", true);
          context.dispatch('getUserInfo');
        })
        .catch((error) => {
          context.commit("SET_LOGIN", false);
        }); */
    },
    async getUserStatistic(context) {
       try {
        const apiUrl = "/api/user/getUserStatistic "; // Замените на ваш URL API
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };

       const response = await axios.get(apiUrl, { headers });
       context.commit("SET_LOGIN", response.data.status === "LOGGED_IN");
    //   if (response.data.status === "NOT_LOGGED_IN")
    //     context.commit("SET_LOGIN", false);
   //    if (response.data.status === "LOGGED_IN")
    //      context.commit("SET_LOGIN", true);
      } catch (error) {
        console.error("Error in main auth function:", error);
        context.commit("SET_LOGIN", false);
      }
    },
  },
  strict: debug,
});
