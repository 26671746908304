// Объект талантов, требуется для отрисовки стилей и инструментов
export const talentsSelect = (code) => {
  return {
    composer: {show :code === "CMP", placeholder: 'Инструменты', style: true, tools: false},
    singer: {show :code === "SNG", placeholder: 'Голос', style: true, tools: true},
    poet: {show :code === "SWR", style: true, tools: false},
    dancer: {show :code === "DNC", placeholder: 'Виды танцев', style: false, tools: true},
    dj: {show :code === "DJ", style: true, tools: true},
    musician: {show :code === "MUS", style: true, tools: true},
    soundEngineer: {show :code === "MMK", style: true, tools: true},
    studio: {show :code === "MST", style: true, tools: true},
    producer: {show :code === "PRO", style: true, tools: true},
    soundMaker: {show :code === "SNM", style: true, tools: true},

  }
};
// Находит выбранные талант и отдает его объект с подробным описанием для таланта
export const isTalentSelect = (code) => {
  const talent = talentsSelect(code);
  const foundTalent = Object.values(talent).find(talent => talent.show);

  return foundTalent || null;
};
// Функция используется для фильтровки тулзов талантов
export const filterTools = (items, filterType, filterTypeTxt) => {
  if (filterType !== "" && filterTypeTxt !== "") {
    return items.filter(
      (item) =>
        item.Type === filterType &&
        item.Name.toLowerCase().includes(filterTypeTxt.toLowerCase())
    );
  } else if (filterType !== "") {
    return items.filter((item) => item.Type === filterType);
  } else if (filterTypeTxt !== "") {
    return items
      .filter((item) =>
        item.Name.toLowerCase().includes(filterTypeTxt.toLowerCase())
      )
      .sort((a, b) => a.Name.localeCompare(b.Name));
  } else {
    return items;
  }
};
